.chip {
  border: 1px solid #E2E4F0;
  background: #FFFFFF;
  border-radius: 50px;
  padding: 0.25rem 1rem !important;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  text-align: justify;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  /* word-break: break-all; */
  max-width: 90%;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #3E4659;
}



.activeChip {
  font-family: 'Avenir';
  background: #E2EEFF;
  border: 1px solid #1A51A3;
  border-radius: 50px;
  padding: 0.25rem 1rem !important;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  text-align: justify;
  /* white-space: nowrap; */
  /* word-break: break-all; */
  text-overflow: ellipsis;
  max-width: 90%;
  color: var(--primary);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chip,
.activeChip {
  background-position: center;
  transition: background 0.8s;
}
.chip:hover,
.activeChip:hover {
  background: rgba(1, 56, 105, 0.1)
    radial-gradient(circle, transparent 1%, rgba(0, 85, 158, 0.1) 1%)
    center/15000%;
}
.chip:active,
.activeChip:active {
  background-color: rgba(0, 85, 158, 0.1);
  background-size: 100%;
  transition: background 0s;
}
