.header {
  padding-right: 1.5rem;
}

.backBtn {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.title {
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #434343;
}

.header h3 {
  font-weight: normal;
  font-style: normal;
  margin: 0.5rem;
}
.search {
  height: 16px;
  width: 16px;
  margin-right: 1rem;
}

.options {
  padding: 0.25rem 0;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06);
}

.sortBtn {
  margin-right: 0.75em !important;
}

.sortBtn,
.filterBtn {
  padding: 0 !important;
  color: var(--primary) !important;
}

.label {
  margin-left: 0.25rem;
}

.chipsContainer {
  min-height: 3rem;
  padding: 0.5em;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border-light);
}

.chip {
  margin-right: 0.5em;
  margin-bottom: 0.25rem;
  background: white !important;
  border: 1px solid var(--border-light) !important;
}

.productsGrid {
  border-top: 0 !important;
  /* max-height: 50%; */
  min-height: 20rem;
  padding-bottom: 2rem;
}

.gridSectionHeader {
  margin: 0 1em 2em 1em;
}
.image {
  max-width: 20rem;
  margin-bottom: 0.25rem;
  margin: 5rem auto 2rem;
}

.message {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: var(--accent-light);
}

.button {
  margin: 0 1.5rem 3rem;
  background-color: var(--primary);
  border-radius: 0.25rem;
}

.button a {
  padding: 1rem;
  text-decoration: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
}

.smallScreenOnly {
  display: none;
}

/* LARGE SCREEN ONLY */
.outerContainer {
  position: relative;
  width: 100vw;
}

.desktopHeader {
  margin: 0 2rem;
}

.desktopHeader h3 {
  font-weight: normal;
}

.desktopListingInfo {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.desktopFiltersContainer {
  display: flex;
}

.desktopFilters {
  max-height: 120px;
  overflow-y: auto;
}

.desktopClearAll {
  color: var(--primary);
  cursor: pointer;
}

.desktopProductsCount {
  flex-shrink: 0;
  padding: 0.5rem 1rem 0.5rem 0;
  margin-right: 1rem;
  border-right: 1px solid var(--border-medium);
}

.desktopProductListing {
  display: flex;
  margin: 2rem;
}

.desktopSortOptions {
  flex-shrink: 0;
  padding: 0.5rem 0 0.5rem 1rem;
  margin: 0 1rem;
  border-left: 1px solid var(--border-medium);
}

.desktopSortByInput {
  margin-left: 0.75rem;
  width: 11rem;
}

.desktopFilterPanel {
  width: 20%;
  margin-right: 1rem;
}

.desktopProducts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 80vh;
}

@media only screen and (max-width: 768px) {
  .largeScreenOnly {
    display: none !important;
  }
  .smallScreenOnly {
    display: initial !important;
  }
}

@media only screen and (min-width: 769px) {
  .smallScreenOnly {
    display: none !important;
  }
  .largeScreenOnly {
    display: initial !important;
  }
  .productsGrid {
    max-height: unset;
    min-height: 20rem;
    grid-template-columns: repeat(4, calc(25% - 0rem)) !important;
  }
  .productsGridAlt{
    max-height: unset;
    grid-template-columns: repeat(6, calc(16.66% - 1rem)) !important;
  }
  
}
