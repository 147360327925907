.paginationContainer {
    position: relative;
    width: 100%;
    margin-top: 1rem;
}

.pagination {
    width: 100%;
    border-top: 1px solid var(--divider);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.pageInputContainer {
    position: absolute;
    right: 5rem;
    top: 50%;
    transform: translate(-50%, -50%);
}

.pageLabel {
    font-size: 0.875rem;
    color: var(--header);
    margin-right: 0.5rem;
}

.pageInput {
    width: 3rem;
    height: 2rem;
    box-sizing: border-box;
    border: 1px solid var(--header) !important;
    border-radius: 0.25rem !important;
    padding: 0 0.25rem !important;
}

@media only screen and (max-width: 768px) {
    .pageInputContainer{
        display: none;
    }
}