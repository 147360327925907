.vsiPaper{
    height:  70vh;
    width: 80vw;
    padding: 16px !important;
    border-radius: 24px !important;
    background: #FEFEFF !important;
    box-shadow: 0px -10px 180px rgba(147, 147, 147, 0.24) !important;
    font-style: normal;
    font-family:'Avenir';
    overflow: hidden;
  }

.vertical{
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}  

.verticalDetails{
width: 100%;
}
.comingSoon{
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  position: absolute;
  color: #ffffff;
  background: rgba(255, 17, 0, 0.5);
  border-radius: 6px;
  margin: 5px 0;
  padding: 2px 4px;
  bottom: 0;
}

.search {
  padding: 0.5rem 1rem !important;
  width: -webkit-fill-available;
  font-size: inherit;
  font-family: inherit;
  border: 1px solid #CCDDEC;
  background: #FFFFFF;
  box-shadow: 0px 13px 56px rgba(136, 122, 166, 0.12);
  border-radius: 12px;
}

.verticals{
  overflow: auto;
  height: 50vh;
}

.description{
  display: block;
  overflow: hidden;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #69778C;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.title{
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #434343;
}
.subTitle{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #8991AC;
}

.imageContainer{
  height: 75px;
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 12px;
  position: relative;
  padding: 5px;
  object-fit: cover;
}

.imageContainer img{
    height: 65px;
    width: 70px;
}