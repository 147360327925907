.container{
    font-size: small;
    display:flex;
    align-items:center;
    padding:6px 3px;
}

.closeIcon, .logoContainer, .buttonContainer{
    display:flex;
    align-items:center;
    justify-content:center;
    padding:10px;
}

.logoContainer{
    padding-left:0px 
}

.closeIcon{
    padding:8px;
}

.buttonContainer{
    padding-left:20px;
}

.title{
    margin:0px;
}

.desc{
    margin:0px;
    color:#595959;
    font-size: smaller;
}

.openAppBtn{
    height: 1.75rem;
    line-height: 1;
    font-size: 0.75rem !important;
    padding: 0.25em !important;
}

.image{
    height:42px
}

@media only screen and (min-width: 769px) {
    .container {
        display: none !important;
    }
}