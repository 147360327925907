.drawerHeader {
    padding: 1em 1em 0.75em 1em;
    box-shadow: 0 -4px 24px rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid var(--border-light);
}

.drawer {
    border-radius: 1.5em 1.5em 0 0 !important;
}

.closeBtn {
    color: var(--accent);
    cursor: pointer;
}

.active {
    font-weight: 500;
    color: black !important;
}

.listItem {
    padding: 0.6em 1em !important;
    color: var(--accent-light);
}

.listItem:first-of-type {
    padding-top: 1em !important;
}

.listItem:last-of-type {
    padding-bottom: 1em !important;
}

.label {
    font-weight: 500;
    color: var(--accent-light);
}
