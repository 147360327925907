.header {
  padding: 1rem;
  background-color: white;
  border-bottom: 1px solid var(--border-light);
}

.location {
  margin-left: 0.75rem;
  font-weight: 500;
  background: #ffffff;
}

.button {
  padding: 0 !important;
  display: flex !important;
  align-items: flex-end !important;
}

.location {
  text-decoration: underline #000 dashed;
}

.caretDownIcon {
  margin-left: 7px;
  margin-bottom: 5px;
}

.sectionHeader {
  padding: 1.25rem;
}
.gridSectionHeader {
  margin: 0.5em 1em 2em 1em;
}

.heading {
  font-size: 1.25rem;
  font-weight: 500;
}

.link {
  color: var(--primary);
  font-weight: 500;
}

/* padding margin fix for drop shadow */
.list {
  min-height: 23rem;
  padding: 3rem 1rem;
  margin: -3rem 0;
  box-sizing: border-box;
  --mask-height: 20px;
}

.list > .product:only-of-type{
  margin: 0 0.5rem !important;
}

.list > .product:last-of-type {
  margin-right:0 ;
}

.list > .product {
  width: 50%;
  max-width: 12rem;
  margin-right: 1rem;
}

.banner {
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
}

.banner img {
  margin: 0 1rem !important;
  min-width: calc(100% - 2rem) !important;
  border-radius: 0.5rem !important;
  object-fit: unset !important;
}

.productsGrid {
  padding-bottom: 1.5rem;
}

/* .hideOnLargeScreens {
  display: initial;
} */

/* Guided Focus */

.focusContainer {
  position: relative;
}
.focusAnchor {
  position: relative;
  z-index: 200000 !important;
  background: #ffffff;
  display: flex !important;
  align-items: flex-end !important;
  border-radius: 5px;
  padding: 4px;
}
.focus {
  position: fixed;
  background: #000000;
  opacity: 0.7;
  width: 300vw;
  height: 300vh;
  top: -20vh;
  left: -20vw;
  z-index: 100000 !important;
}

.toggleDropdown {
  position: relative;
  z-index: 200000;
  background: #02559e;
  padding: 10px;
  border-radius: 5px;
}

.focusArrow {
  position: absolute;
  z-index: 200000;
  top: 138%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

.focusText {
  color: #ffffff;
  width: 12rem;
  text-align: center;
}

.recommendedProductsHeader{
  padding:  1.25rem 0;
 }

 .endSection{
   margin-bottom: 2rem !important;
 }

 .desktopPromotion { 
  margin-top: 4rem;
  width: 100%;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}

@media screen and (max-width: 768px) {
  .desktopPromotion{
    display: none !important;
  }

}

@media only screen and (min-width: 769px) {
  .header,
  .hideOnLargeScreens {
    display: none !important;
  }

  .sectionHeader {
    margin: 0 20px;
  }

  .list{
    margin-left: 1rem;
    width: calc(100% - 50px);
    --mask-height: 10px;
  }
.list > .product {
  min-width: 12.5rem;
}
}

