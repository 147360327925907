.clear {
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--primary);
}

.search_field_container {
  background: white;
  min-width:100px;
  width: 90%;
  border-color: transparent !important;
  border-radius: 1.5rem;
  padding: 0 0.5rem;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--border-light) !important;
}
.crossIcon {
  cursor: pointer;
  flex-shrink: 0;
  margin-left:5px;
}

.searchField {
  width: 95%;
  min-width: 85px;
  background-color: transparent !important;
  border-bottom: none !important;
}
.searchIcon {
  cursor: pointer;
}

.filterGroup {
  padding: 1.5rem 1rem;
  background-color: white;
  border-bottom: 1px solid var(--border-light);
}

.filterTitle {
  margin: 0;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--headline-text);
}

.filterList {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1.5rem;
}

.filterChip {
  border: 1px solid var(--border-light);
  border-radius: 1.5rem;
  padding: 0.25rem 1rem;
}

.linkButton {
  height: 48px;
  padding: 1rem;
  margin: 1.5rem 1rem 1.5rem 1rem;
  background-color: var(--primary);
  width: 100%;
  border-radius: 0.25rem;
}

.linkButton span {
  text-decoration: none;
  color: #fff;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
}

.slider {
  margin: 0.5rem !important;
  height: 0.5rem;
}

.show_more_less {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #00559e;
  margin-top: 18px;
  cursor: pointer;
}

.desktopHeader > h1 {
  text-align: left;
}

@media only screen and (min-width: 769px) {
  .search_field_container {
    width: 85%;
    min-width: unset;
  }
  .searchField {
    width: 95%;
    min-width: unset;
  }
}
