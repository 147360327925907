.select{
    position:relative
}

.label {
    width: max-content;
    font-weight: 500;
    font-size: 0.75rem;
    color: var(--accent-light);
    margin-bottom: 0.6rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.label > sup {
    color: red;
    margin-left: 0.25rem;
}

.searchBar {
    position: absolute;
    top:0px;
    right:0px;
    left:0px;
    bottom:0px;
    padding:10px;
    /* background:blue; */
    z-index: 100;

}

.searchInputContainer{
    border: 1px solid #C5C7D1;
    max-width: 90%;
    margin-left:auto;
    margin-right:auto;
    padding:5px 10px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    pointer-events: all !important ;
}

.searchIcon{
    flex:0.5;
}

.searchInput{
    flex:11.5;
    border:none;
    background: none;
    width:100%;
}

.searchInput:focus{
    border:none;
    outline: none;
    text-decoration: none;
}

.searchContainer{
    /* border:2px solid red !important; */
    /* position: fixed !important; */
    top:0px !important;
    /* background:red !important; */
    z-index: 1000 !important;
    opacity:1 !important;
    width:100%
    /* height:60px; */
  
}

.searchContainer div:first-child {
    border-radius: 40px;
    /* border:2px solid red; */
  }
