/* SMALL SCREEN ONLY */
.sectionHeader {
  padding: 1rem;
  margin-top: 1.5rem;
}

.link {
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
  z-index: 10;
}

.heading {
  font-size: 1.25rem;
  font-weight: 500;
}

.smallScreenOnly {
  display: none;
}

/* LARGE SCREEN ONLY */
.outerContainer {
  padding: 40px;
  position: relative;
}

.innerContainer {
  display: flex;
  border: 1.5px solid #dde0eb;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background: #ffffff;
  margin: 20px 40px 0 40px;
}

.categoryListTitle {
  border-bottom: 1.5px solid #dde0eb !important;
  padding-bottom: 10px;
  padding-left: 10px;
}
.scroll{
  max-height: 24rem;
  overflow-y: hidden;
}

.categoryList {
  flex: 3;
  z-index: 150;
}

.sliderContainer {
  flex: 9;
  max-width: 68% !important;
  height: 30rem;
  padding-left: 15px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 100;
}

.sliderContainer img {
  z-index: 100 !important;
  height: 30rem;
  width: 100%;
  cursor: pointer;
  object-fit: contain;
}

.largeCarousel {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
}

.largeCarousel .slick-track {
  display: flex !important;
}

.largeCarousel .slick-slide {
  height: inherit !important;
}

.dropdownData {
  z-index: 150 !important;
  /* border:2px solid red; */
}

.dropdownContainer {
  min-height: 10rem;
  z-index: 150 !important;
}

@media only screen and (max-width: 768px) {
  .largeScreenOnly {
    display: none !important;
  }
  .smallScreenOnly {
    display: initial !important;
  }
}

@media only screen and (min-width: 769px) {
  .smallScreenOnly {
    display: none !important;
  }
  .largeScreenOnly {
    display: initial !important;
  }
}
.scroll:hover {
  overflow-y: auto;
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background-color: rgba(105, 119, 140, 0.5);
  display: block;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 85, 158, 0.5);
}