.categoryListItem p{
    color:#3E4659;
    margin:0px !important;
    min-width:10rem;
    flex:8
}

.categoryListItem img{
   width:35px;
   margin-right:10px;
   flex:1;
}

.categoryListItemChevron{
    flex:3
}

.categoryListItem{
    display:flex;
    padding:10px;
    align-items: center;
    cursor: pointer;
}

.categoryListItemOpen{
    background:#E4EEF5;
    border-radius:5px;
    z-index: 2000 !important;
}

.categoryListing{
    display: flex;
    min-width: 40rem;
    padding:10px
}

.categoryListingContainer{
    flex:6;
    min-width:20rem;
    height:23rem;
    /* overflow-y: auto; */
}

.listing{
    height:20rem;
    overflow-y: auto;
}

.listingItem{
   cursor: pointer;
   /* border:1px solid red; */
}