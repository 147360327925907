.container{
  font-family: 'Avenir';
  font-style: normal;
}

.promptContainer{
 padding: 0 40px;
 background: url("public/images/promotionalBg.svg");
 background-size: cover;
 background-position: center;
}
 
.title{
margin-right: 20px;
font-weight: 600;
font-size: 45px;
line-height: 55px;
text-transform: capitalize;
color: var(--alerzo-blue);
}

.title span{
color:var(--success);
}

.prompt{
font-weight: 600;
font-size: 15.3582px;
line-height: 21px;
text-transform: uppercase;
color: var(--headline-text);
}

.container img{
padding-top: 20px;
}

.button {
background: var(--alerzo-blue) !important;
padding: 0px !important;
border-radius: 6px !important;
margin-bottom: 5px !important;
margin-right: 10px !important;
}

.about{
margin:40px;
font-weight: 500;
font-size: 16px;
line-height: 23px;
color: var(--headline-text);
text-align: justify;
}

.about span{
font-weight: 400;
color: var(--accent-light);
}