.sectionHeader {
padding: 1rem 1.25rem 0 1.25rem;
}

.vertical{
font-family: 'Avenir';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;
color: #434343;
}

.caretIcon{
    transform: rotate(90deg);
    height: 12px;
    width: 12px;
    align-self: center;
    margin-left: 2px;
}

.caretIcon path{
    fill: #434343;
}
.chipsContainer {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    box-sizing: border-box;
    --mask-height:8px;
  }
  
  .chip {
    border: 1px solid #E2E4F0;
    background: #FFFFFF;
    border-radius: 50px;
    border-radius: 10px !important;
    text-transform: capitalize;
    cursor: pointer;
    display: inline-block;
    text-align: justify;
    text-overflow: ellipsis;
    max-width: 90%;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 20px;
    color: #3E4659;
    margin-right: 0.5em !important;
  }
  

  
  .activeChip{
    background : #1A51A3 !important;
    text-transform: capitalize;
    border-radius: 10px !important;
    cursor: pointer;
    display: inline-block;
    text-overflow: ellipsis;
    color: #FFFFFF !important;
    max-width: 90%;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 20px;
    margin-right: 0.5em !important;
  }
  
  .loadingContainer{
    position: relative;
    padding-top: 1.5em;
    z-index: 1;
  }
  @media only screen and (min-width: 769px) {
    .smallScreenOnly {
      display: none !important;
    }
  }