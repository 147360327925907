/* .searchBar {
    background-color: white;
    border-bottom: 1px solid var(--border-light);
} */

.icon {
    fill: var(--accent-light) !important;
    height: 1rem;
    width: 1rem;
}

.searchBar input {
/* padding: 0.6rem 0; */
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
color: #8991AC;
}

.clearBtn {
    cursor: pointer;
    color: black;
}

.clearBtn > .icon {
    height: 1.25rem;
    width: 1.25rem;
}

.clearBtn > .icon > path {
    fill: black !important;
}
